<template>
  <div class="order-detail">
    <van-nav-bar
      title="订单详情"
      left-text="返回"
      left-arrow
      @click-left="click_left"
    />
    <div class="view flex jc-sb aic">
      <span>订单已取消</span>

      <img
        src="https://file.sdyxmall.com/h5/v1/public/app/img/cancel.ff91da7.png"
        alt=""
      />
    </div>
    <div class="w100 bg-fff" style="height: 40px"></div>
    <div class="orderGoods bg-fff">
      <div class="flex">
        <img
          src="https://mall.s.maizuo.com/0238d75b8a9e13c5a18df9781c296c83.png"
          alt=""
        />
        <div class="price flex fdc jc-sb">
          <p class="f14">【云南】新鲜冰糖橙 5斤装 (果径60mm+/枚) 20-30个</p>
          <div class="flex jc-sb">
            <div>
              <span class="red">¥</span>
              <span class="red f16">29.</span>
              <span class="red">.90</span>
            </div>
            <span class="f14" style="color: #969799"
              >x<span class="f16">1</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="payPrice pg bg-fff mt-10">
      <div class="flex jc-sb mb-10">
        <span style="color: #797d82">商品金额</span>
        <span>
          <span>¥</span>&nbsp;
          <span>29.90</span>
        </span>
      </div>
      <div class="flex jc-sb mb-10">
        <span style="color: #797d82">运费</span>
        <span>
          <span>¥</span>&nbsp;
          <span>0.00</span>
        </span>
      </div>
      <div class="flex jc-sb aic">
        <span class="f16">实付</span>
        <span class="red f16">
          <span>¥</span>&nbsp;
          <span>29.90</span>
        </span>
      </div>
    </div>
    <div class="pg bg-fff mt-10">
      <div class="flex jc-sb">
        <p style="color: #797d82">
          <span class="mr-10">订单编号</span>
          <span>6152c819272e660fdee5dcb7</span>
        </p>
        <van-button plain hairline style="height: 20px" type="default"
          >复制</van-button
        >
      </div>
      <p style="color: #797d82">
        <span class="mr-10">下单时间</span>
        <span>2021-09-28 07:45:29</span>
      </p>
    </div>
    <div class="footer fixed flex jc-sb aic f16 bg-fff">
      <p class="pl-10">订单状态：已取消</p>
      <div class="mr-15">
        <van-button plain hairline style="height: 32px" type="default"
          >重新购买</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    //返回上一个页面
    click_left() {
      this.$router.go(-1);
    },
  },
};
</script>
<style  lang="less" scoped>
.van-nav-bar /deep/ .van-icon,
.van-nav-bar /deep/.van-nav-bar__text {
  color: #c03131;
}
.order-detail {
  min-height: 100vh;
  background: #f4f4f4;
}
.view {
  background: url(https://file.sdyxmall.com/h5/v1/public/app/img/group5@2x.0b65469.png)
    no-repeat;
  background-size: 100%;
  height: 80px;
  span {
    color: white;
    padding-left: 40px;
    font-size: 16px;
  }
  img {
    padding-right: 30px;
    height: 66px;
  }
}
.orderGoods {
  padding: 15px;
  img {
    height: 100px;
  }
}
.red {
  color: #c03131;
}
.pg {
  padding: 12px 15px;
}
.footer {
  width: 100%;
  bottom: 0px;
  height: 60px;
}
</style>